.modal--overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 9999;
  overflow: hidden;

  .container {
    position: relative;
    height: 100%;
  }
}

.modal__close {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  width: 50px;
  padding: 0;
  right: 30px;
  top: 20px;
  cursor: pointer;

  .svg-icon--close {
    @include transition(fill);

    &:hover {
      fill: $color_yellow;
    }
  }
}
