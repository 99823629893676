.cookie-consent-preferences {
  display: flex;
  justify-content: flex-start;

  margin: 50px 0 15px;

  > div {
    cursor: pointer;
    @include font-size(14);
    font-family: $font__museo--700;
  }
}

// popup cookie policy box
.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: #fff;
}
.termsfeed-com---nb-simple {
  max-width: 530px;
  right: 15px;
  width: 90vw;
  border-radius: 5px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.75);
  right: 15px;
  bottom: 15px;

  .cc-nb-main-container {
    padding: 25px;

    p {
      font-family: $font__museo--300;
    }

    a,
    button,
    .cc-nb-title {
      font-family: $font__museo--700;
    }

    button {
      border-radius: 20px;
    }
  }
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background-color: #4accc7;
}

// Overlay cookie policy dialog box
.termsfeed-com---pc-dialog {
  .cc-pc-container {
    border-radius: 5px;
    overflow: hidden;

    p,
    select,
    .cc-cp-foot {
      font-family: $font__museo--300;
    }

    .cc-cp-body-content-entry-title,
    .cc-pc-head-title-headline {
      font-family: $font__museo--700;
    }

    a,
    button:not(.cc-cp-body-tabs-item-link) {
      font-family: $font__museo--700;
      border-radius: 20px;
    }
  }
}

.cc-pc-head-lang-select {
  box-shadow: 0 0 0 2px #ededed !important;
}

.termsfeed-com---palette-light .cc-pc-head-lang select:focus {
  box-shadow: 0 0 0 2px #4accc7 !important;
}

.termsfeed-com---palette-light .cc-pc-head-close:active,
.termsfeed-com---palette-light .cc-pc-head-close:focus {
  border: 2px solid #4accc7;
}

.termsfeed-com---palette-light .cc-cp-foot-save {
  background-color: #4accc7;
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox:checked + label:before {
  background: #4accc7;
}
