/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

// base link styling
.navigation__link {
  color: $color_dark-grey;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: $font__museo--700;
  cursor: pointer;

  span {
    text-align: center;
    width: 100%;
  }
}

// Header nav styling
.navigation--header .navigation__link {
  background-color: #fff;
  text-decoration: none;
  height: 100%;
  align-items: center;
  font-family: $font__museo--1000;
  padding: 0 10px;
  position: relative;
  @include font-size(18);

  @include transition(color, background-color, font-size);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(14);

    background-color: transparent;
  }

  @media only screen and (min-width: $screen-lg-min) {
    padding: 0 5px;
  }

  @media only screen and (min-width: $screen-xxl-min) {
  }

  &:after {
    content: '';
    position: absolute;
    transition: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: $color_yellow;

    @include transition(height 0.3s ease-in-out 0.3s);
  }

  &.active {
    @include font-size(18);
    color: $color_dark-grey;
    background-color: $color_yellow;

    @media only screen and (min-width: $screen-md-min) {
      background-color: transparent;
    }
    &:after {
      height: 100%;
    }
  }
  &:hover:not(.active) {
    color: $color_yellow;
  }
}

// Header nav mobile styling
@media only screen and (max-width: $screen-md-min) {
  .navigation--header {
    .navigation__list li {
      @include transition(width 0.2s);
    }

    .navigation__link span {
      @include transition(opacity 0.2s);
      text-align: left;
    }

    &.navigation--open {
      .navigation__list li {
        width: 100%;
      }

      .navigation__link span {
        opacity: 1;
      }
    }

    &.navigation--close {
      .navigation__list li {
        width: 0%;
        @include transition(width 0.2s);
      }
      .navigation__link span {
        opacity: 0;
        @include transition(opacity 0.2s);
      }
    }
  }
}

@media only screen and (min-width: $screen-md-min) {
  .navigation__list li,
  .navigation__link span {
    transition-delay: 0s !important;
  }

  .navigation__list li {
    height: 100% !important;
  }
}
